import authService from '../services/auth/auth.service';
import store from "../store/index";
import router from "../router";

export default (to, from, next) => {
    if (to.meta && to.meta.auth) {
        if (!store.getters.isTokenSoonExpires && authService.fetchAuth()) {
            next();
        } else {
            store.dispatch('signOut').then(() => {
                next({name: 'login'});
            });
        }
    } else {
        if (!store.getters.isAuthorized || !authService.fetchAuth()) {
            next();
        } else {
            router.push("/");
        }
    }
    next();
}
