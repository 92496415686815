import DefaultClientService from '@/services/client/default.client.service';
import DefaultTelegramClientService from '@/services/client/default.telegram.client.service';

export default {
    state: {
        defaultClient: null,
        defaultTelegramClient: null,
    },

    getters: {
        getDefaultClient(state) {
            return state.defaultClient
        },
        getDefaultTelegramClient(state) {
            return state.defaultTelegramClient
        },
    },

    actions: {
        setDefaultClientToApi({getters, commit}, client) {
            return new Promise((resolve, reject) => {
                if (!client)
                    DefaultClientService.delete(getters.getDefaultClient.clientId).then(function () {
                        commit('setDefaultClient', {client: null});
                        resolve({client: null});
                    });
                else
                    DefaultClientService.set(client).then(function (client) {
                        if (client) {
                            commit('setDefaultClient', {client: client});
                            resolve({client: client});
                        }
                    });
            })

        },
        getDefaultClientFromApi({commit}) {
            return new Promise((resolve, reject) => {
                DefaultClientService.get().then(function (client) {
                    if (client) {
                        commit('setDefaultClient', {client: client});
                    }
                    resolve();
                }, function (error) {
                    reject(error)
                });
            })
        },

        setDefaultTelegramClient({getters, commit}, client) {
            if (!client)
                DefaultTelegramClientService.delete().then(function () {
                    commit('setDefaultTelegramClientService', {client: null});
                });
            else
                DefaultTelegramClientService.set(client).then(function (client) {
                    if (client) {
                        commit('setDefaultTelegramClientService', {client: client});
                    }
                });
        },
        async getTelegramDefaultClient({commit}) {
            await DefaultTelegramClientService.get().then(function (client) {
                if (client) {
                    commit('setDefaultTelegramClientService', {client: client});
                }
            }, function (error) {
                console.log(error)
            });
        },
    },

    mutations: {
        setDefaultClient(state, data) {
            state.defaultClient = data.client;
        },
        setDefaultTelegramClientService(state, data) {
            state.defaultTelegramClient = data.client;
        }
    }
}
