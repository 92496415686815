import BaseService from "../base/base.service";
import axios from "axios";

class WarehouseService extends BaseService {
    get modelName() {
        return "Warehouse";
    }
    getAll(params) {
        let qParams = {
            q: (typeof (params.query) === "undefined" || params.query === null) ? "" : params.query,
            sort: (typeof (params.sort) === "undefined" || params.sort === null) ? "" : params.sort,
            desc: params.desc,
            skip: params.skip,
            limit: params.limit
        };

        return axios.get(this.getAllUrl(), {params: qParams}).then(response => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error)
        });
    }
    get(id) {
        return axios.get(this.getSingleUrl(id))
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return Promise.reject(error);
            })
    }
    create(warehouse) {
        return axios.post(this.getCreateUrl(), {
            Name: warehouse.Name
        }).then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
    }

    edit(warehouse) {
        return axios.put(this.getEditUrl(warehouse.WarehouseId), {
            Name: warehouse.Name
        }).then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
    }

    delete(id) {
        return axios.delete(this.getDeleteUrl(id)).then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
    }
}

export default new WarehouseService()
