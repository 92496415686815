import OrganizationService from '../../../services/organization/organization.service';

export default {
    state: {
        organization: null,
    },
    getters: {
        getOrganization(state) {
            return state.organization
        }
    },

    actions: {
        async loadOrganization({commit}) {
            await OrganizationService.get().then(function (org) {
                commit('setOrganization', {organization: org});
            });
        },
    },
    mutations: {
        setOrganization(state, data) {
            state.organization = data.organization;
        },
    }
}
