import BaseService from "../base/base.service";
import axios from "axios";

class OrganizationService extends BaseService {
    get modelName() {
        return 'Organization'
    }

    get() {
        return axios.get(this.getAllUrl()).then(function (response) {
            return response.data
        }, function (error) {
            console.log(error)
        })
    }
}

export default new OrganizationService();
