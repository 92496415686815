import {ApiHost} from '@/constants/settings';

export default class BaseService {
    constructor() {
        if (!this.modelName) {
            throw new Error('Child service class not provide modelName')
        }
    }

    apiHost(ExternalApi) {
        if (!ExternalApi)
            return ApiHost;
        if (ExternalApi)
            return ExternalApi;
    }

    getSingleUrl(id) {
        return `${this.apiHost()}/api/${this.modelName}/${id}`
    }

    getAllUrl() {
        return `${this.apiHost()}/api/${this.modelName}`
    }

    getCreateUrl() {
        return `${this.apiHost()}/api/${this.modelName}`
    }

    getMergeUrl() {
        return `${this.apiHost()}/api/${this.modelName}`
    }

    getEditUrl(id) {
        return `${this.apiHost()}/api/${this.modelName}/${id}`
    }

    getDeleteUrl(id) {
        return `${this.apiHost()}/api/${this.modelName}/${id}`
    }

    _getDateDDMMYYYFormat(date) {
        if (date) {
            let day = date.getDate().toString();
            day = day.length === 1 ? `0${day}` : day;

            let month = (date.getMonth() + 1).toString();
            month = month.length === 1 ? `0${month}` : month;

            let year = date.getFullYear().toString();

            return `${day}.${month}.${year}`;
        }
        return "";
    }

    _getDateDDMMYYYHHMMCCFormat(datetime) {
        if (datetime) {
            let day = datetime.getDate().toString();
            day = day.length === 1 ? `0${day}` : day;

            let month = (datetime.getMonth() + 1).toString();
            month = month.length === 1 ? `0${month}` : month;

            let year = datetime.getFullYear().toString();

            let hr = datetime.getHours();
            let min = datetime.getMinutes();
            if (min < 10) {
                min = "0" + min;
            }
            let date = `${day}.${month}.${year}`;
            let time = `${hr}:${min}:00`;

            return date + ' ' + time;
        }
        return "";
    }
}

