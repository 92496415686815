import BaseService from "../base/base.service";
import axios from "axios";

class CurrencyService extends BaseService {
    get modelName() {
        return "Currency";
    }

    getAll({sort, desc, skip, limit}) {
        let params = {
            sort: sort,
            desc: desc,
            skip: skip,
            limit: limit
        };
        return axios.get(this.getAllUrl(), {params: params}).then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
    }

    create(currency) {
        return axios.post(this.getCreateUrl(), {
            Name: currency.Name,
            Rate: currency.Rate
        }).then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error)
        });
    }

    edit(currency) {
        return axios.put(this.getEditUrl(currency.CurrencyId), {
            Name: currency.Name,
            Rate: currency.Rate
        }).then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error)
        });
    }

    delete(id) {
        return axios.delete(this.getDeleteUrl(id)).then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
    }
}

export default new CurrencyService()
