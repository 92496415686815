export const ApiHost = "https://hippo.sog.uz";
export const ItemApiHost = "https://item.hippo.uz";
export const UzPos = "http://localhost:8347/uzpos";

/*export const ApiHost = "https://test.hippo.uz";
export const ItemApiHost = "https://testitem.hippo.uz";
export const UzPos = "http://178.218.200.198:8347/uzpos";*/

export const updateInfoUrl = 'https://telegra.ph/Tizim-yangilandi-06-06';

