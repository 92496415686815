import BaseService from "../base/base.service";
import axios from "axios";

class DefaultTelegramClient extends BaseService {
    get modelName() {
        return "DefaultTelegramClient";
    }

    get() {
        return axios.get(this.getAllUrl()).then((response) => {
            return response ? response.data : null;
        }).catch(error => {
            return Promise.reject(error);
        })
    }

    set(Client) {
        return axios.put(this.getEditUrl(Client.ClientId)).then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
    }

    delete() {
        return axios.delete(this.getDeleteUrl()).then(() => {
        }).catch((error) => {
            return Promise.reject(error);
        })
    }
}

export default new DefaultTelegramClient()
