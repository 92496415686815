import DefaultEmployeeService from '../../../services/employee/default.employee.service';

export default {
    state: {
        defaultEmployee: null,
    },

    getters: {
        getDefaultEmployee(state) {
            return state.defaultEmployee
        },
    },
    actions: {
        async getDefaultEmployee({commit}) {
            await DefaultEmployeeService.get().then(function (response) {
                if (response && response.data) {
                    commit('setDefaultEmployee', {employee: response.data});
                }
            }, function (error) {
                console.log(error)
            });
        },
    },

    mutations: {
        setDefaultEmployee(state, data) {
            state.defaultEmployee = data.employee;
        },
    }
}
