import BaseService from "../base/base.service";
import axios from "axios";

class PeriodlyCurrencyService extends BaseService {
    get modelName() {
        return "PeriodlyCurrency";
    }

    get(data) {
        let params = {
            start: this._getDateDDMMYYYFormat(data.start),
            end: this._getDateDDMMYYYFormat(data.end),
        }
        return axios.get(this.getSingleUrl(data.id), {params: params}).then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
    }
}

export default new PeriodlyCurrencyService();
