import Vue from 'vue'
import formatter from '../plugins/formatter';
import truncate from '../plugins/truncate';
import KitCut from '../plugins/kitcut';
import moment from 'moment'
import store from "../store/index";

Vue.filter('date', (date) => {
    const format = 'DD.MM.YYYY'
    return (moment(date).format(format))
});

Vue.filter('time', (date) => {
    const format = 'HH:mm:ss'
    return (moment(date).format(format))
});

Vue.filter('datetime', (date) => {
    const format = 'DD.MM.YYYY HH:mm:ss'
    return (moment(date).format(format))
});

Vue.filter("decimal", (value) => {
    let val = +value;
    return formatter.decimalToString(val);
});

Vue.filter("truncate", (value) => {
    return truncate.truncateTwoDecimalPlaces(value);
});

Vue.filter("kitcut", (text) => {
    return KitCut.kitCutText(text);
});

Vue.filter('phoneNumber', (value) => {
    let cleaned = ('' + value).replace(/\D/g, '');
    let match = cleaned.match(/^(\d{3})(\d{2})(\d{3})(\d{2})(\d{2})$/);
    if (match)
        return '+' + match[1] + ' ' + match[2] + ' ' + match[3] + '-' + match[4] + '-' + match[5]

    return null
})

Vue.filter("division", (value, currency) => {
    let selectedCurrency = store.getters.selectedCurrency;
    if (value === null || value === undefined || value === 0 || !selectedCurrency) {
        return value;
    }
    if (currency && (selectedCurrency.CurrencyId === currency.CurrencyId)) {
        return value;
    }
    return value / selectedCurrency.Rate;
});

