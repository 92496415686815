export default {
    state: {
        statusEPos: false,
        startedTime: ''
    },
    getters: {
        getStatusEPos(state) {
            return state.statusEPos
        },
        getStartedTime(state) {
            return state.startedTime
        },
    },
    mutations: {
        setStatusEPos(state, {statusEPos}) {
            state.statusEPos = statusEPos
        },
    }
}
