import DailyCurrency from "@/services/currency/daily.currency";
import PeriodlyCurrencyService from "@/services/currency/periodly.currency.service";
import CurrencyService from "@/services/currency/currency.service";

export default {
    state: {
        currencies: [],
        selectedCurrency: null,
        periodlyCurrency: null
    },
    getters: {
        selectedCurrency: state => state.selectedCurrency,
        getAllCurrencies: state => state.currencies,
        getPeriodsCurrency: state => state.periodlyCurrency,
    },
    actions: {
        changeCurrency({commit, dispatch}, selectedCurrency) {
            commit('selectCurrency', {currency: selectedCurrency});
            let d = new Date();
            let params = {
                id: selectedCurrency.CurrencyId,
                start: new Date(d.setMonth(d.getMonth() - 3)),
                end: new Date()
            }
            dispatch('getPeriodlyCurrency', params);
        },
        async getAllCurrencies({commit}, params) {
            await CurrencyService.getAll(params).then(function (currencies) {
                commit('setCurrencies', {currencies: currencies});
            }, function (error) {
                console.log(error)
            });
        },
        getDailyCurrency({commit}, params) {
            return new Promise((resolve, reject) => {
                DailyCurrency.get(params)
            });
        },
        getPeriodlyCurrency({commit}, data) {
            return new Promise((resolve, reject) => {
                PeriodlyCurrencyService.get(data).then(function (response) {
                    commit('setPeriodlyCurrency', {periodlyCurrency: response});
                }, function (error) {
                    return error;
                })
            });
        }
    },
    mutations: {
        setCurrencies(state, {currencies}) {
            state.currencies = currencies;
        },
        selectCurrency(state, {currency}) {
            state.selectedCurrency = currency;
        },
        setPeriodlyCurrency(state, {periodlyCurrency}) {
            state.periodlyCurrency = periodlyCurrency
        }
    },
}
