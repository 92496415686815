import BaseService from "../base/base.service";
import axios from "axios";

class DailyCurrency extends BaseService {
    get modelName() {
        return "DailyCurrency";
    }

    get(id, date) {
        let params = {
            date: date
        }
        return axios.get(this.getSingleUrl(id), {params: params}).then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
    }
}
export default new DailyCurrency();
