import axios from "axios";
import BaseService from "../base/base.service";

class DefaultEmployeeService extends BaseService {
    get modelName() {
        return "DefaultEmployee";
    }

    get() {
        return axios.get(this.getAllUrl());
    }
}

export default new DefaultEmployeeService()
